module.exports = {
  ENV: 'prod',
  APP_NAME: 'ape',
  END_POINT: 'https://ssm.services.apihf.com',
  API_KEY: 'a1Jv8beujQal0VvdxItVp8rVre8D3xFp5DYNAavt',
  BRAND_KEY: '8131cde5-4b5b-45c2-9139-18de50bf7fa5',
  CLIENT_ID: '2hcnhauq2qob6f67q6ikcb7of1',
  USER_POOL_ID: 'eu-west-1_HIn9p5IYR',
  HEADER_MESSAGE_SIZE: 41,
  AGENTS_GROUP: 'APE_AGENTS',
}